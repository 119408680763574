/**
 * Created by vladislav on 19.02.2020
 */

var KnockoutPlayerView = cc.Node.extend({
    ctor: function (player) {
        this._super();

        this.player = player;

        this.setAnchorPoint(0.5, 0.5);

        this.createAvatar();
        this.createBar();

        var styles = cleverapps.styles.KnockoutPlayerView.layout;
        var layout = new cleverapps.Layout([this.avatar, this.bar], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
        this.addChild(layout);
        layout.setPositionRound(styles);

        cleverapps.UI.wrap(this);
    },

    createAvatar: function () {
        var avatar = this.avatar = new cleverapps.UI.Avatar(this.player, {
            frame: this.player.frame
        });

        var frame = this.frame = new cc.Sprite(bundles.main.frames.frame_pulsation);
        frame.setCascadeColorEnabled(true);
        avatar.addChild(this.frame);
        this.frame.setLocalZOrder(-1);
        this.frame.setVisible(false);
        this.frame.setScale(avatar.width / this.frame.width);
        this.frame.setPositionRound(cleverapps.styles.KnockoutPlayerView.frame);
    },

    createBar: function () {
        var bar = this.bar = new ScaledProgressBar({
            progress: bundles.progress_bar.frames.bar_player_png,
            background: bundles.progress_bar.frames.bg_player_png,
            barText: {
                font: cleverapps.styles.FONTS.KNOCKOUT_BAR_TEXT
            }
        });

        bar.setGoal(Game.currentGame.totalItemsToSolve());
        bar.setLength(this.avatar.width);
        bar.setPercentage(this.player.amount / Game.currentGame.totalItemsToSolve());
    },

    checkPlayerPointOpened: function () {
        this.bar.setPercentage(this.player.amount, {
            animated: true
        });
    },

    runForceAvatarAnimation: function (callback) {
        var baseScale = this.avatar.getScale();
        this.avatar.runAction(new cc.Spawn(
            new cc.Sequence(
                new cc.RotateTo(0.15, -10),
                new cc.RotateTo(0.15, 10),
                new cc.RotateTo(0.15, -5),
                new cc.RotateTo(0.15, 5),
                new cc.RotateTo(0.1, 0),
                new cc.CallFunc(callback)
            ),
            new cc.Sequence(
                new cc.ScaleTo(0.4, baseScale * 1.3),
                new cc.ScaleTo(0.4, baseScale * 1.0)
            )
        ));
    },

    runUserBehindAnimation: function () {
        if (this.userBehindRunning) {
            return;
        }
        if (cleverapps.forces.isRunningForce()) {
            return;
        }

        this.userBehindRunning = true;

        this._runFrameAnimation();
    },

    _runFrameAnimation: function () {
        if (!this.frame) {
            return;
        }

        if (!this.userBehindRunning) {
            this.frame.setVisible(false);
            return;
        }

        this.frame.setVisible(true);
        this.frame.runAction(
            new cc.Sequence(
                new cc.ScaleTo(0.4, 1.05 * this.frame.getScale()),
                new cc.ScaleTo(0.4, this.frame.getScale()),
                new cc.CallFunc(this._runFrameAnimation.bind(this))
            )
        );
    },

    stopUserBehindAnimation: function () {
        this.userBehindRunning = false;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    KNOCKOUT_BAR_TEXT: {
        name: "default",
        size: 32,
        color: cleverapps.styles.COLORS.WHITE,
        stroke: cleverapps.styles.DECORATORS.IMAGE_FONT_STROKE
    }
});

cleverapps.styles.KnockoutPlayerView = {
    frame: {
        x: { align: "center" },
        y: { align: "center", dy: 2 }
    },

    layout: {
        x: { align: "center" },
        y: { align: "center" },
        margin: 10
    }
};